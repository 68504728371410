import React, { Suspense, lazy, useEffect, useState } from 'react';
import { isBrowser } from '../../utils';

const AdminPage = lazy(() => import('../../private-pages/admin'));

const Loading = () => (<h1>Loading...</h1>);

const AdminWrapper = () => {
  const [isInBrowser,setIsInBrowser] =  useState(false);
  useEffect(() => {
    if (isBrowser()) {
      setIsInBrowser(true);
    }
  }, []);


  if (!isInBrowser) {
    return (<Loading />);
  }

  return (
    <Suspense fallback={() => (<Loading />)}>
      <AdminPage/>
    </Suspense>
  );
};

export default AdminWrapper;
